<template>
    <div> <img src="@/assets/solutionpdf/guns_1.jpg" alt="" />
    <img src="@/assets/solutionpdf/guns_2.jpg" alt="" />
    <img src="@/assets/solutionpdf/guns_3.jpg" alt="" />
    <img src="@/assets/solutionpdf/guns_4.jpg" alt="" />
    <img src="@/assets/solutionpdf/guns_5.jpg" alt="" />
    <img src="@/assets/solutionpdf/guns_6.jpg" alt="" /></div>
</template>

<script>
export default {
    name: "guns-solution"
}
</script>

<style scoped>
img {
  width: 100%;
}
</style>